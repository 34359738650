import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { CookieManager } from "utils/cookie_manager";
import { GlobalStateContext } from "contexts/global_state_context";
import { AuthorizationForm, useLoginApi } from "api/authorization";
import { useForm } from "utils/hooks";

import { Image, Space, theme } from "antd";
import {
  CustomInputField,
  StringValidation,
  TelValidation,
} from "specifics/input";
import { CustomButton } from "specifics/button";
import { HistoryProps } from "routes/app";
import useMedia from "use-media";

import Logo from "components/../../public/logo.png";
import { getEnvColor, hankaku2Zenkaku } from "utils/util";

const LoginPage = ({ history }: HistoryProps) => {
  const apiSet = useLoginApi();
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);

  const isMobile = useMedia("(max-width: 519px)");
  const initialForm: AuthorizationForm =
    process.env.REACT_APP_ENV_MODE === "PRODUCTION" || process.env.REACT_APP_ENV_MODE === "DEVELOPMENT"
      ? {
        mobile: "",
        password: "",
      }
      : {
        mobile:
          process.env.REACT_APP_TYPE === "ADMIN"
            ? "09052174873"
            : process.env.REACT_APP_TYPE === "SUPER_ADMIN"
              ? "09012344321"
              : "08055310751",
        password: "ogawa",
      };
  const form = useForm<AuthorizationForm>(initialForm);

  const validationResultForm = useForm<AuthorizationForm>({
    mobile: "",
    password: "",
  });

  useEffect(() => {
    validationResultForm.update((f) => {
      TelValidation(f, form, "mobile", true);
      StringValidation(f, form, "password", true);
    });
  }, [JSON.stringify(form.object)]);

  useEffect(() => {
    globalState.setLoading(apiSet.loading);
    if (apiSet.isSuccess() && !!apiSet.response.token) {
      CookieManager.saveUserToken(apiSet.response.token);
      CookieManager.saveUserId(apiSet.response.userid);
      globalState.setLoading(false);
      let nextPath;
      switch (process.env.REACT_APP_TYPE) {
        case "ADMIN":
          nextPath = "/operations/orders";
          break;
        case "DRIVER":
          nextPath = "/drivers/delivers";
          break;
        case "SUPER_ADMIN":
          nextPath = "/super-admin/templates";
          break;
      }

      nextPath && history.push(nextPath);
    }
  }, [apiSet.loading]);

  useEffect(() => {
    globalState.setError(apiSet.apiError);
  }, [apiSet.apiError]);

  const handleClickLoginButton = () => {
    apiSet.execute(form);
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: isMobile ? "flex-start" : "center",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        background: getEnvColor(),
      }}
    >
      <Space
        style={{
          backgroundColor: token.colorWhite,
          width: isMobile ? globalState.dimension.width - 30 : 448,
          padding: isMobile ? "0px 0px" : "32px 40px 32px 40px",
          zIndex: 1,
          boxShadow: isMobile ? "none" : "0 0.5mm 0.5mm rgba(0, 0, 0, 0.3)",
          borderRadius: 6,
        }}
        direction="vertical"
        size={24}
      >
        <div
          style={{
            textAlign: "center",
            marginTop: isMobile ? 60 : 100,
            marginBottom: 40,
          }}
        >
          {process.env.REACT_APP_ENV_MODE === "PRODUCTION" ? (
            <Image src={Logo} preview={false} width={180} />
          ) : (
            <h1
              style={{
                background: token.colorWhite,
              }}
            >
              {process.env.REACT_APP_ENV_MODE}/{process.env.REACT_APP_TYPE}
            </h1>
          )}
        </div>

        <CustomInputField
          required
          validationResultForm={validationResultForm}
          label="携帯電話番号"
          placeholder="08012345678"
          attr="mobile"
          form={form}
          onChange={(e) => {
            const value = hankaku2Zenkaku(e.target.value);
            if (value.match(/^\d{0,13}$/)) {
              form.updateObject("mobile", value.slice(0, 13));
            } else {
              console.log("invalid");
            }
          }}
        />
        <CustomInputField
          required
          validationResultForm={validationResultForm}
          label="パスワード"
          placeholder="パスワード"
          attr="password"
          form={form}
          type="password"
          style={{ marginBottom: 0 }}
        />
        <CustomButton
          type="primary"
          onClick={() => {
            form.setValidate(true);
            if (
              Object.keys(validationResultForm.object).every(
                (key) =>
                  validationResultForm.object[
                  key as keyof AuthorizationForm
                  ] === undefined
              )
            ) {
              handleClickLoginButton();
            }
          }}
          style={{
            width: "100%",
            height: !isMobile ? 42 : 50,
            paddingTop: !isMobile ? "auto" : 9,
            paddingBottom: !isMobile ? "auto" : 9,
          }}
        >
          ログイン
        </CustomButton>
        <CustomButton
          style={{
            width: "100%",
            height: !isMobile ? 42 : 50,
            paddingTop: !isMobile ? "auto" : 9,
            paddingBottom: !isMobile ? "auto" : 9,
          }}
          onClick={() => history.push("/forget-password")}
        >
          パスワードを忘れた場合
        </CustomButton>
      </Space>
    </Space>
  );
};

export default withRouter(LoginPage);
