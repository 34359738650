import { useState, useEffect, useContext, ReactNode, useCallback } from "react";
import { Table, Button, Skeleton, theme, Space } from "antd";
import { BaseEntity } from "entities";
import { GlobalStateContext } from "contexts/global_state_context";
import { TableProps } from "antd/lib/table";
import { TableSet } from "./use_table";
import { FormAttrType, ParsableValue } from "utils/hooks";
import { CSSProperties } from "styled-components";
import { CookieManager } from "utils/cookie_manager";
import { CustomButton } from "./button";
import { CustomContentFooter } from "./footer";
import { ReorderModal } from "./reorder_modal";

type ColumnSetting = {
  key: string;
  hide: boolean;
  order: number;
};

export type TableSettings = {
  [columnKey: string]: ColumnSetting;
};

export type CustomTableProps = {
  extraLeft?: ReactNode;
  extraRight?: ReactNode;
  showSizeChanger?: boolean;
  onBulkDelete?: () => void;
  onBulkURLCopy?: () => void;
  onBulkQRPrint?: () => void;
  hideDisplaySetting?: boolean;
}

export const CustomTable = <RecordType extends Record<string, any>>(
  props: TableProps<RecordType> & CustomTableProps & {
    table: TableSet<RecordType>;
    tableKey: string;
  }
) => {
  const {
    style,
    columns,
    onRow,
    scroll,
    table,
    pagination,
    rowSelection,
    showSizeChanger,
    onBulkDelete,
    onBulkURLCopy,
    onBulkQRPrint,
    tableKey,
    extraLeft,
    extraRight,
    ...rest
  } = props;
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);

  const [open, setOpen] = useState(false);

  const [settings, setSettings] = useState<TableSettings>({});
  useEffect(() => {
    setSettings(CookieManager.getTableSettings(tableKey));
  }, [tableKey]);

  const columnsSelector = useCallback(
    // columnsのrenderの内部が更新された場合に表示設定適用後のcolumnsのrenderも更新されるように、
    // 引数にcolumnsを受け取る形式にしている
    (columns: TableProps<RecordType>["columns"]) => {
      const sortedColumns = [...(columns ?? [])].sort(
        (a, b) =>
          (settings[a.key as string]?.order ?? 0) -
          (settings[b.key as string]?.order ?? 0)
      );
      const renderedColumns = sortedColumns.filter(
        (col) => !(settings[col.key as string]?.hide ?? false)
      );
      return { sortedColumns, renderedColumns };
    },
    [JSON.stringify(settings)]
  );

  return (
    <Skeleton loading={globalState.loading}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <div>{extraLeft}</div>
          {!props.hideDisplaySetting && (
            <Space>
              <div>{extraRight}</div>
              <Button onClick={() => setOpen(true)}>表示設定</Button>
            </Space>
          )}
        </Space>
        <Table
          bordered
          scroll={{ y: "calc(100vh - 310px)", ...scroll }}
          style={style}
          onRow={(data, index) => {
            if (onRow) {
              return {
                style: { cursor: "pointer", color: token.colorTextSecondary },
                ...onRow(data, index),
              };
            } else return {};
          }}
          onHeaderRow={(data) => {
            return { style: { height: 40, borderRadius: 8 } };
          }}
          columns={columnsSelector(columns).renderedColumns}
          pagination={{ ...pagination, showSizeChanger: showSizeChanger }}
          rowSelection={rowSelection}
          {...rest}
        />
        <CustomContentFooter
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: "calc(100vw - 200px)",
            backgroundColor: token.colorWhite,
            borderTop: `1px solid ${token.colorBorder}`,
            display:
              rowSelection?.selectedRowKeys?.length && onBulkDelete ? "flex" : "none",
            justifyContent: "space-between",
          }}
        >
          <Space>
            {onBulkQRPrint && (
              <CustomButton type="primary" onClick={onBulkQRPrint}>
                QRコードを印刷
              </CustomButton>
            )}
            {onBulkURLCopy && (
              <CustomButton type="primary" onClick={onBulkURLCopy}>
                URLをコピー
              </CustomButton>
            )}
            {onBulkDelete && (
              <CustomButton
                type="primary"
                dialogconfirmProps={{
                  width: 328,
                  title: (
                    <span>
                      {rowSelection?.selectedRowKeys?.length}
                      件の納入カルテを削除します。
                      <br />
                      よろしいですか？
                    </span>
                  ),
                  okText: "削除する",
                  onOk: onBulkDelete,
                  cancelText: "戻る",
                  okButtonProps: {
                    danger: true,
                    style: { background: "#FFEFEE" },
                  },
                  icon: null,
                  centered: true,
                  closable: false,
                }}
              >
                削除
              </CustomButton>
            )}
          </Space>
          <div style={{ fontSize: 13 }}>
            <span style={{ fontSize: 15 }}>
              {rowSelection?.selectedRowKeys?.length}
            </span>
            件を選択中
          </div>
        </CustomContentFooter>
      </Space>
      <ReorderModal
        open={open}
        setOpen={setOpen}
        settings={settings}
        setSettings={setSettings}
        tableKey={tableKey}
        columns={columns}
      />
    </Skeleton>
  );
};

type AttrColumnProps<RecordType> = {
  attr: FormAttrType<RecordType>;
  label?: string;
  width?: number | string;
  align?: "left" | "right" | "center";
  render?: (item: RecordType) => ParsableValue;
  style?: CSSProperties;
};

export const AttrColumn = <RecordType extends BaseEntity>(
  props: AttrColumnProps<RecordType>
) => {
  const { attr, label, width, align, render, style } = props;
  return {
    key: typeof attr === "string" ? attr : JSON.stringify(attr) || undefined,
    title: label,
    render: (item: RecordType) => (
      <div style={style}>
        {render
          ? (render(item) as ReactNode)
          : (getObjectValue(item, attr) as ReactNode) ?? "--"}
      </div>
    ),
    width,
    align,
  };
};

export const getObjectValue = <RecordType extends BaseEntity>(
  object: RecordType,
  attr: FormAttrType<RecordType>
): ParsableValue => {
  let returnValue = null;
  try {
    if (object instanceof Object) {
      if (attr instanceof Array) {
        let selectObj = object as { [key: string]: any };
        attr.map((a, index) => {
          if (index + 1 == attr.length) {
            returnValue = selectObj[a];
          } else {
            selectObj = selectObj[a] as { [key: string]: any };
          }
        });
      } else {
        const selectObj = object as { [key: string]: any };
        returnValue = selectObj[attr as string];
      }
    } else {
      throw "updateArray method require object type object";
    }
  } catch {
    return null;
  }
  return returnValue;
};
