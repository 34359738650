import { useState, ReactNode, useCallback } from "react";
import { Button, Modal, theme, Space, message } from "antd";
import { BaseEntity } from "entities";
import { ColumnType, TableProps } from "antd/lib/table";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CookieManager } from "utils/cookie_manager";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import { ColumnGroupType } from "antd/es/table";
import { rearrangeArray } from "utils/util";
import { TableSettings } from "./table";

type ReorderModalProps<RecordType> = {
  open: boolean;
  setOpen: (open: boolean) => void;
  settings: TableSettings;
  setSettings: (settings: TableSettings) => void;
  tableKey: string;
  columns: TableProps<RecordType>["columns"];
};

export const DisplaySettingItem = <RecordType extends BaseEntity>({
  settings,
  col,
  onChangeColVisibleState,
}: {
  settings: TableSettings;
  col: ColumnGroupType<RecordType> | ColumnType<RecordType>;
  onChangeColVisibleState: () => void;
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);
  return (
    <Space
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        background: hover ? token.colorBgLayout : "inherit",
        paddingLeft: 6,
        paddingRight: 12,
        borderRadius: 6,
        // color: settings[col.key as string]?.hide
        //   ? token.colorTextDisabled
        //   : "inherit",
      }}
    >
      <HolderOutlined />
      <Button
        icon={
          settings[col.key as string]?.hide ? (
            <EyeInvisibleOutlined style={{ color: token.colorTextDisabled }} />
          ) : (
            <EyeOutlined />
          )
        }
        type="text"
        onClick={(e) => {
          e.stopPropagation();
          onChangeColVisibleState();
        }}
      />
      {col.title as ReactNode}
    </Space>
  );
};

export const ReorderModal = <RecordType extends Record<string, any>>({
  open,
  setOpen,
  settings,
  setSettings,
  tableKey,
  columns,
}: ReorderModalProps<RecordType>) => {
  const columnsSelector = useCallback(
    // columnsのrenderの内部が更新された場合に表示設定適用後のcolumnsのrenderも更新されるように、
    // 引数にcolumnsを受け取る形式にしている
    (columns: TableProps<RecordType>["columns"]) => {
      const sortedColumns = [...(columns ?? [])].sort(
        (a, b) =>
          (settings[a.key as string]?.order ?? 0) -
          (settings[b.key as string]?.order ?? 0)
      );
      const renderedColumns = sortedColumns.filter(
        (col) => !(settings[col.key as string]?.hide ?? false)
      );
      return { sortedColumns, renderedColumns };
    },
    [JSON.stringify(settings)]
  );

  return (
    <Modal
      title="表示設定"
      open={open}
      footer={
        <Space>
          <Button
            onClick={() => {
              setSettings({});
              CookieManager.removeTableSettings(tableKey);
            }}
          >
            デフォルトに戻す
          </Button>
          <Button type="primary" onClick={() => setOpen(false)}>
            閉じる
          </Button>
        </Space>
      }
      onCancel={() => setOpen(false)}
    >
      <DragDropContext
        onDragEnd={(result, provided) => {
          if (typeof result.destination?.index === "number") {
            const newSettings: TableSettings = {};
            rearrangeArray(
              columnsSelector(columns).sortedColumns,
              result.source.index,
              result.destination?.index
            ).forEach((col, order) => {
              newSettings[col.key as string] = {
                ...settings[col.key as string],
                order,
              };
            });

            setSettings(newSettings);
            CookieManager.setTableSettings(tableKey, newSettings);
            message.info("列の順番を変更しました。");
          }
        }}
      >
        <Droppable droppableId={"table-config"}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
            >
              {columnsSelector(columns).sortedColumns?.map((col, index) => (
                <Draggable
                  key={col.key}
                  draggableId={`${col.key}`}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        key={col.key}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DisplaySettingItem
                          settings={settings}
                          col={col}
                          onChangeColVisibleState={() => {
                            const newSettings = {
                              ...settings,
                              [col.key as string]: {
                                ...settings[col.key as string],
                                hide: !settings[col.key as string]?.hide,
                              },
                            };
                            setSettings(newSettings);
                            CookieManager.setTableSettings(
                              tableKey,
                              newSettings
                            );
                          }}
                        />
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
}; 