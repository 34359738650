import { Space, theme } from "antd";
import {
  useCancelCloseOrdersApi,
  useDeleteOrderApi,
  useFetchOrderApi,
} from "api/order";
import { GlobalStateContext } from "contexts/global_state_context";

import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DescriptionBlock } from "specifics/description";

import { CustomPageHeader } from "specifics/page_header";
import { CustomShadowedContent } from "specifics/shadowed_content";
import { useEffectSkipFirst } from "utils/hooks";
import {
  DeleteOrCancelCloseOrderButton,
  getOrderCharterTypeLabel,
  OrderDetailsView,
  OrderTypeView,
} from "./orders_view";

const OrderPage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const orderApi = useFetchOrderApi();

  const { token } = theme.useToken();
  const params = useParams<{ id: string }>();
  useEffect(() => {
    orderApi.execute(params.id);
  }, []);

  const deleteApi = useDeleteOrderApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(deleteApi.loading);
    if (deleteApi.isSuccess()) {
      props.history.push("/operations/orders");
    }
  }, [deleteApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(orderApi.loading);
  }, [orderApi.loading]);

  const cancelCloseOrdersApi = useCancelCloseOrdersApi();
  useEffectSkipFirst(() => {
    globalState.setLoading(cancelCloseOrdersApi.loading);
    if (cancelCloseOrdersApi.isSuccess()) {
      orderApi.execute(params.id);
    }
  }, [cancelCloseOrdersApi.loading]);

  const order = () => orderApi.response.data;

  return (
    <CustomPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title={
        <Space align="center">
          <div
            style={{
              fontWeight: 700,
              fontSize: "18px",
            }}
          >
            受注詳細
          </div>
          <div
            style={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          >
            {OrderTypeView(orderApi.response.data)}
          </div>
        </Space>
      }
      onBack={() => props.history.goBack()}
      childrenStyle={{
        display: "flex",
        justifyContent: "center",
        padding: "32px 32px 0px 32px",
      }}
    >
      <CustomShadowedContent>
        <Space direction="vertical" style={{ width: "100%" }} size={24}>
          <Space
            style={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "start",
            }}
          >
            <Space size={24}>
              <DescriptionBlock label="車両">
                {getOrderCharterTypeLabel(order().charterType)}
              </DescriptionBlock>
              {order().orderType === 0 && (
                <>
                  <DescriptionBlock label="車格">
                    {order().weight ?? "--"}t
                  </DescriptionBlock>
                  <DescriptionBlock label="金額">
                    {order().price ? `${order().price}円` : "--"}
                  </DescriptionBlock>
                </>
              )}
            </Space>
            <Space size={24} style={{ verticalAlign: "top", height: "100%" }}>
              <CustomButton
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                }}
                onClick={() =>
                  props.history.push(`/operations/orders/${order()?.id}/edit`)
                }
                disabled={!!order()?.isConvert}
              >
                編集
              </CustomButton>
              <DeleteOrCancelCloseOrderButton
                item={order()}
                handleCancelClose={(id: string) =>
                  cancelCloseOrdersApi.execute({ ids: [id] })
                }
                handleDelete={(id) => deleteApi.execute(id)}
              />
            </Space>
          </Space>
          <Space
            style={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "start",
            }}
          >
            <DescriptionBlock label="受注番号">
              {order()?.orderNo}
            </DescriptionBlock>
          </Space>
          <DescriptionBlock label="荷主">
            {order()?.consignor?.name}
          </DescriptionBlock>

          <Space>
            <DescriptionBlock label="登録日">
              {dayjs(order().createdAt).format("YYYY/MM/DD")}
            </DescriptionBlock>
            <DescriptionBlock label="受注日">
              {dayjs(order().orderDate).format("YYYY/MM/DD")}
            </DescriptionBlock>
          </Space>
          {([2, 1] as const).map((pointType) => (
            <OrderDetailsView
              key={pointType}
              order={order()}
              pointType={pointType}
            />
          ))}

          <DescriptionBlock label="備考">
            {order()?.memo}
          </DescriptionBlock>
        </Space>
      </CustomShadowedContent>
    </CustomPageHeader>
  );
};

export default withRouter(OrderPage);
