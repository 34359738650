import { Space, TableProps, theme } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ConsignorsResponse } from "api/consignor";

import { ID } from "entities";
import { Consignor } from "entities/consignor";
import dayjs from "dayjs";
import Highlighter from "react-highlight-words";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { CustomTable } from "specifics/table";
import { CustomTableText } from "specifics/table_text";
import { useTable } from "specifics/use_table";
import { IndexApiSet } from "utils/network/api_hooks";

type ConsignorsTableProps = TableProps<Consignor>;

export const ConsignorsTable = (
  props: ConsignorsTableProps &
    HistoryProps & { handleDelete: (id: ID) => void } & {
      listApi?: IndexApiSet<ConsignorsResponse> & {
        execute: (query?: string) => void;
      };
    }
) => {
  const { token } = theme.useToken();
  const table = useTable<Consignor>(props);

  const columns: ColumnsType<Consignor> = [
    {
      ...table.baseColumn("name"),
      ...table.getColumnSearchProps("name", "名称"),
      title: "名称",
      width: 150,
      render: (item) => {
        return <CustomTableText width={150}>{item.name}</CustomTableText>;
      },
      fixed: "left",
    },
    {
      ...table.baseColumn("kana"),
      ...table.getColumnSearchProps("kana", "ふりがな"),
      title: "ふりがな",
      width: 200,
      render: (item) => {
        return <CustomTableText width={200}>{item.kana}</CustomTableText>;
      },
    },
    {
      ...table.baseColumn("address"),
      ...table.getColumnSearchProps("address", "住所"),
      title: "住所",
      width: 266,
      render: (item) => {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[table.searchText]}
            autoEscape
            textToHighlight={item.address ?? "--"}
          />
        );
      },
    },
    {
      ...table.baseColumn("repName"),
      ...table.getColumnSearchProps("repName", "担当者氏名"),
      title: "担当者氏名",
      width: 180,
      render: (item) => {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[table.searchText]}
            autoEscape
            textToHighlight={item.repName ?? "--"}
          />
        );
      },
    },
    {
      ...table.baseColumn("repTel"),
      ...table.getColumnSearchProps("repTel", "電話番号"),
      title: "電話番号",
      width: 180,
      render: (item) => {
        return (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[table.searchText]}
            autoEscape
            textToHighlight={item.repTel ?? "--"}
          />
        );
      },
    },
    {
      ...table.baseColumn("updatedAt"),
      sorter: (a, b) => ((a.updatedAt ?? "") > (b.updatedAt ?? "") ? 1 : -1),
      title: "最終更新日",
      width: 127,
      render: (item) => dayjs(item.updatedAt).format("YYYY/MM/DD"),
    },
    {
      key: "action",
      title: "アクション",
      align: "left",
      width: 138,
      render: (item) => (
        <Space size={16}>
          <CustomButton
            style={{
              color: token.colorText,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px 11px",
              height: "30px",
              width: "46px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              letterSpacing: "-0.1em",
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
              verticalAlign: "middle",
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/operations/consignors/${item.id}/edit`);
            }}
          >
            編集
          </CustomButton>
          <CustomButton
            style={{
              fontWeight: 500,
              padding: 0,
              height: "30px",
              width: "28px",
              textAlign: "left",
            }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              props.handleDelete(item.id);
            }}
            danger
            confirm
            popconfirmProps={{
              title: "削除します。よろしいですか？",
            }}
          >
            削除
          </CustomButton>
        </Space>
      ),
    },
  ];

  return (
    <CustomTable
      tableKey="consignors_table"
      onChange={table.handleChange}
      table={table}
      pagination={table.paginationInfo}
      columns={columns}
      onRow={(data, index) => ({
        onClick: () => props.history.push(`/operations/consignors/${data.id}`),
      })}
      {...props}
    />
  );
};
