import { Button, notification, Space, Table, theme, TreeSelect } from "antd";
import { GlobalStateContext } from "contexts/global_state_context";

import { DeliveryPartnerCompany } from "entities/delivery_partner_company";
import { useContext, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { HistoryProps } from "routes/app";
import { CustomButton } from "specifics/button";
import { DeleteOutlined } from "@ant-design/icons";
import {
  CustomInputField,
  CustomInputNumberField,
  CustomSelectField,
  CustomTreeSelectField,
  EmailValidation,
  KanaValidation,
  LabelView,
  StringValidation,
  TelValidation,
  ZipCodeValidation,
} from "specifics/input";
import { CustomFormPageHeader } from "specifics/page_header";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { DescriptionBlock } from "specifics/description";
import { CustomContentFooter } from "specifics/footer";
import { CustomShadowedContent } from "specifics/shadowed_content";
import {
  useEditDeliveryPartnerCompanyApi,
  useFetchDeliveryPartnerCompanyApi,
  usePostDeliveryPartnerCompanyApi,
} from "api/delivery_partner_company";
import {
  findAddressByZip,
  hankaku2Zenkaku,
  PrefectureArea,
  Prefectures,
} from "utils/util";
import { DeliveryPartnerVehicle } from "entities/delivery_partner_vehicle";
import { getIndex } from "./delivery_partner_companies_view";
import { VehicleTypes } from "entities/vehicle";

const { SHOW_CHILD } = TreeSelect;

const FormView = (
  form: Form<DeliveryPartnerCompany>,
  validationResultForm: Form<DeliveryPartnerCompany>
) => {
  const globalState = useContext(GlobalStateContext);

  const isEdit = !!form.object.id;
  const { token } = theme.useToken();

  useEffect(() => {
    validationResultForm.update((f) => {
      StringValidation(f, form, "name", true);
      KanaValidation(f, form, "kana", true);
      ZipCodeValidation(f, form, "zipcode", true);
      StringValidation(f, form, "address", true);
      StringValidation(f, form, "repName");
      EmailValidation(f, form, "email", false);
      TelValidation(f, form, "tel", false);
      StringValidation(f, form, "area", false);
    });
  }, [JSON.stringify(form.object)]);

  return (
    <Space style={{ width: "100%" }} size={16} direction="vertical">
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="名称"
        attr="name"
        required
      />
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="ふりがな"
        attr="kana"
        required
      />
      <Space direction="vertical">
        <CustomInputField
          validationResultForm={validationResultForm}
          form={form}
          label="郵便番号"
          onChange={(e) => {
            const value = hankaku2Zenkaku(e.target.value);
            if (
              e.target.value.length === 0 ||
              value.match(/^\d{1,3}-?\d{0,4}$/)
            ) {
              form.updateObject(
                "zipcode",
                value.length > 3 && value[3] !== "-" && !value.includes("-")
                  ? value.slice(0, 3) + "-" + value.slice(3)
                  : value.slice(0, 8)
              );
            } else {
              console.log("invalid");
            }
          }}
          attr="zipcode"
          required
          style={{ width: 180 }}
        />
      </Space>
      <CustomInputField
        form={form}
        label="住所"
        attr="address"
        style={{ width: "100%" }}
        required
        validationResultForm={validationResultForm}
      />
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="担当者氏名"
        attr="repName"
      />
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="電話番号"
        attr="tel"
        onChange={(e) => {
          const value = hankaku2Zenkaku(e.target.value);
          if (value.match(/^\d{0,13}$/)) {
            form.updateObject("tel", value.slice(0, 13));
          } else {
            console.log("invalid");
          }
        }}
      />
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="メールアドレス"
        attr="email"
      />
      <Space style={{ width: "100%" }} direction="vertical" size={0}>
        <DescriptionBlock
          label={<LabelView label="保有車両" />}
          valueStyle={{
            height: "auto",
          }}
          childrenEmpty={<></>}
        >
          {form.object?.deliveryPartnerVehicles?.map(
            (vehicle, index) =>
              vehicle.flag !== "d" && (
                <div
                  key={vehicle?.id}
                  style={{
                    width: 755,
                    background: token.colorBgBase,
                    borderRadius: 8,
                    marginBottom: 4,
                    padding: 16,
                  }}
                >
                  <Space
                    style={{
                      width: 720,
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>{getIndex(form.object, index) + 1}.</div>
                    {getIndex(form.object, index) !== 0 && (
                      <Button
                        shape="circle"
                        icon={<DeleteOutlined style={{ color: "#B91C1C" }} />}
                        type="dashed"
                        onClick={() => {
                          form.updateObject(
                            "deliveryPartnerVehicles",
                            form.object.deliveryPartnerVehicles?.filter(
                              (_, idx) => idx !== index
                            )
                          );
                        }}
                      />
                    )}
                  </Space>
                  <Space
                    style={{
                      width: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    <CustomSelectField
                      required
                      validationResultForm={validationResultForm}
                      label="車両種類"
                      form={form}
                      attr={["deliveryPartnerVehicles", index, "vehicleType"]}
                      style={{ width: 135 }}
                      selectItems={VehicleTypes.map((type, i) => ({
                        label: type,
                        value: i,
                      }))}
                      onChange={(e) => {
                        form.update((f) => {
                          if (f.deliveryPartnerVehicles?.length) {
                            f.deliveryPartnerVehicles[index].vehicleType = e;
                            if (isEdit && f.deliveryPartnerVehicles[index].id) {
                              f.deliveryPartnerVehicles[index].flag = "u";
                            }
                          }
                        });
                      }}
                    />
                    <CustomInputNumberField
                      required
                      validationResultForm={validationResultForm}
                      label="車格"
                      form={form}
                      attr={["deliveryPartnerVehicles", index, "weight"]}
                      fieldProps={{ addonAfter: "t", min: 0, precision: 0 }}
                      style={{ width: 135 }}
                      onChange={(e) => {
                        form.update((f) => {
                          if (f.deliveryPartnerVehicles?.length) {
                            f.deliveryPartnerVehicles[index].weight = e;
                            if (isEdit && f.deliveryPartnerVehicles[index].id) {
                              f.deliveryPartnerVehicles[index].flag = "u";
                            }
                          }
                        });
                      }}
                    />
                    <CustomInputNumberField
                      required
                      validationResultForm={validationResultForm}
                      label="最大積載量"
                      form={form}
                      attr={["deliveryPartnerVehicles", index, "maxWeight"]}
                      fieldProps={{ addonAfter: "t", min: 0, precision: 0 }}
                      style={{ width: 135 }}
                      onChange={(e) => {
                        form.update((f) => {
                          if (f.deliveryPartnerVehicles?.length) {
                            f.deliveryPartnerVehicles[index].maxWeight = e;
                            if (isEdit && f.deliveryPartnerVehicles[index].id) {
                              f.deliveryPartnerVehicles[index].flag = "u";
                            }
                          }
                        });
                      }}
                    />
                    <CustomInputNumberField
                      validationResultForm={validationResultForm}
                      label="パレット数"
                      form={form}
                      attr={["deliveryPartnerVehicles", index, "maxPallets"]}
                      fieldProps={{ min: 0, precision: 0 }}
                      style={{ width: 135 }}
                    />
                    <CustomInputNumberField
                      validationResultForm={validationResultForm}
                      label="運賃"
                      form={form}
                      attr={["deliveryPartnerVehicles", index, "fare"]}
                      fieldProps={{ addonAfter: "円", min: 0, precision: 0 }}
                      style={{ width: 135 }}
                    />
                  </Space>
                </div>
              )
          )}
        </DescriptionBlock>
        <div
          key={"add-load-button"}
          style={{
            width: 755,
            background: token.colorBgBase,
            textAlign: "center",
            alignItems: "flex-start",
            borderRadius: 8,
            padding: 10,
          }}
        >
          <CustomButton
            onClick={() =>
              form.updateObject("deliveryPartnerVehicles", [
                ...(form.object.deliveryPartnerVehicles ?? []),
                isEdit ? { flag: "i" } : {},
              ])
            }
          >
            保有車両を追加
          </CustomButton>
        </div>
      </Space>
      <CustomTreeSelectField
        validationResultForm={validationResultForm}
        form={form}
        label="主要エリア"
        attr="area"
        style={{ width: 180 }}
        onChange={(e) => {
          form.updateObject("area", e.join(","));
        }}
        treeData={Object.keys(PrefectureArea).map((area, index) => ({
          title: area,
          value: `area ${index}`,
          key: `area ${index}`,
          children: PrefectureArea[area].map((prefectureId) => ({
            title: Prefectures[prefectureId],
            value: prefectureId,
            key: prefectureId,
          })),
        }))}
        fieldProps={{
          treeCheckable: true,
          showCheckedStrategy: SHOW_CHILD,
          value: form.object.area?.split(","),
          showSearch: false,
        }}
      />
      <CustomInputField
        validationResultForm={validationResultForm}
        form={form}
        label="備考"
        attr="memo"
      />
    </Space>
  );
};

const _DeliveryPartnerCompanyCreatePage = (props: HistoryProps) => {
  const globalState = useContext(GlobalStateContext);
  const postApi = usePostDeliveryPartnerCompanyApi();
  const form = useForm<DeliveryPartnerCompany>({
    companyId: globalState.user.companyId,
    deliveryPartnerVehicles: [{} as DeliveryPartnerVehicle],
  });
  const validationResultForm = useForm<DeliveryPartnerCompany>({});
  const { token } = theme.useToken();
  // form.setValidate(true);

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      props.history.goBack();
    }
  }, [postApi.loading]);

  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="運送会社登録"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() =>
        props.history.push("/operations/delivery-partner-companies")
      }
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every((key) => {
                  const value =
                    validationResultForm.object[key as keyof DeliveryPartnerCompany];
                  if (key === "deliveryPartnerVehicles") {
                    return validationResultForm.object.deliveryPartnerVehicles?.every(
                      (item: DeliveryPartnerVehicle) =>
                        Object.keys(item).every(
                          (k) => item[k as keyof DeliveryPartnerVehicle] === undefined
                        )
                    );
                  } else {
                    return value === undefined;
                  }
                })
              ) {
                postApi.execute(form);
              }
            }}
          >
            登録
          </CustomButton>
          <CustomButton
            onClick={() => props.history.push("/operations/delivery-partner-companies")}
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const DeliveryPartnerCompanyCreatePage = withRouter(
  _DeliveryPartnerCompanyCreatePage
);

const _DeliveryPartnerCompanyEditPage = (props: HistoryProps) => {
  const { token } = theme.useToken();
  const globalState = useContext(GlobalStateContext);
  const deliveryPartnerCompanyApi = useFetchDeliveryPartnerCompanyApi();
  const params = useParams<{ id: string }>();
  const form = useForm<DeliveryPartnerCompany>({});
  useEffect(() => {
    deliveryPartnerCompanyApi.execute(params.id);
  }, []);
  const validationResultForm = useForm<DeliveryPartnerCompany>({});
  const editApi = useEditDeliveryPartnerCompanyApi();

  useEffectSkipFirst(() => {
    globalState.setLoading(deliveryPartnerCompanyApi.loading);
    if (deliveryPartnerCompanyApi.isSuccess()) {
      form.set({
        ...deliveryPartnerCompanyApi.response.data,
        deliveryPartnerVehicles: deliveryPartnerCompanyApi.response.data.deliveryPartnerVehicles
          ?.length
          ? deliveryPartnerCompanyApi.response.data.deliveryPartnerVehicles
          : [
            {
              flag: "i",
            } as DeliveryPartnerVehicle,
          ],
      });
    }
  }, [deliveryPartnerCompanyApi.loading]);

  useEffectSkipFirst(() => {
    globalState.setLoading(editApi.loading);
    if (editApi.isSuccess()) {
      props.history.push(`/operations/delivery-partner-companies/${params.id}`);
    }
  }, [editApi.loading]);
  return (
    <CustomFormPageHeader
      style={{
        backgroundColor: token.colorWhite,
        borderBottom: `1px solid ${token.colorBorder}`,
      }}
      title="運送会社編集"
      childrenStyle={{ display: "flex", justifyContent: "center" }}
      handleLogoClick={() =>
        props.history.push("/operations/delivery-partner-companies")
      }
    >
      <CustomShadowedContent style={{ padding: 0 }}>
        <div style={{ padding: 24 }}>
          {FormView(form, validationResultForm)}
        </div>
        <CustomContentFooter>
          <CustomButton
            type="primary"
            onClick={() => {
              form.setValidate(true);
              if (
                Object.keys(validationResultForm.object).every((key) => {
                  const value =
                    validationResultForm.object[key as keyof DeliveryPartnerCompany];
                  if (key === "deliveryPartnerVehicles") {
                    return validationResultForm.object.deliveryPartnerVehicles?.every(
                      (item: DeliveryPartnerVehicle) =>
                        Object.keys(item).every(
                          (k) => item[k as keyof DeliveryPartnerVehicle] === undefined
                        )
                    );
                  } else {
                    return value === undefined;
                  }
                })
              ) {
                editApi.execute(form);
              }
            }}
          >
            保存
          </CustomButton>
          <CustomButton
            onClick={() => props.history.push("/operations/delivery-partner-companies")}
            popconfirmProps={{
              title: "編集内容を破棄しますか？",
              okText: "破棄",
              cancelText: "キャンセル",
            }}
            confirm
          >
            キャンセル
          </CustomButton>
        </CustomContentFooter>
      </CustomShadowedContent>
    </CustomFormPageHeader>
  );
};

export const DeliveryPartnerCompanyEditPage = withRouter(_DeliveryPartnerCompanyEditPage);
